import {  Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './login.css';
import swal from 'sweetalert2';



function Login() {
  useEffect(() => {
    // Dynamically create a link element
    const link = document.createElement('link');
    link.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css';
    link.rel = 'stylesheet';
    link.id = 'bootstrap-css';

    // Append the link element to the head
    document.head.appendChild(link);

    // Cleanup function to remove the link when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  const [isEmail, setIsEmail] = useState(true);
  const [isEmail2, setIsEmail2] = useState(true);

  const [showCountryCode, setShowCountryCode] = useState(false);
  const [showCountryCode2, setShowCountryCode2] = useState(false);

  const [isReg, setIsReg] = useState(true);
  const [showReg, setShowReg] = useState(false);
  const [seed, setSeed] = useState(1);
       const reset = () => {
            setSeed(Math.random());
            setShowReg(false);
            setFormData({
              fullName: '',
              email: '',
              MobileNumber: '',
              password: '',
              location: ''
            });
        }

  const [selectedCountry, setSelectedCountry] = useState(90);
  const [selectedCountry2, setSelectedCountry2] = useState(90);
  const loginStatus=JSON.parse(localStorage.getItem('loginstatus'));

  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    MobileNumber: '',
    password: '',
    location: ''
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    getAllCountryCode();
  }, []);

  const getAllCountryCode = async () => {
    try {
      const resp = await axios.get('https://mabkart.org/user/getcountrycode');
      setCountries(resp.data.data);
    } catch (error) {
      console.error('Error fetching country codes:', error);
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  const handleCountryChange2 = (event) => {
    setSelectedCountry2(event.target.value);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'logincredential') {
      const isNumber = /^\d+$/.test(value);
      setShowCountryCode(isNumber);
      setIsEmail(!isNumber);

      if (isNumber) {
        setFormData({
          ...formData,
          MobileNumber: value,
          email: ''
        });
      } else {
        setFormData({
          ...formData,
          email: value,
          MobileNumber: ''
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const doAction = () => {
    if (isReg) {
      checkUserExistence();
    } else {
      doRegister();
    }
  };

  const doRegister = async () => {
    console.log('doRegister function called');
    
    if (!((formData.MobileNumber || formData.email) && formData.password && formData.fullName && formData.location)) {
      alert('Please fill in all required fields.');
      console.log('Required fields missing:', formData);
      return;
    }
       // Validate mobile number
  if (formData.MobileNumber.length <= 6 && !isEmail) {
    alert('Mobile number too short');
    return;
  }
   // Validate email
      if (!emailRegex.test(formData.email) &&isEmail ) {
        alert('Invalid email format');
        return;
      }
    
        // Validate password
        if (formData.password.length < 6) {
          alert('Password must be at least 6 characters');
          return;
        }
    try {
      console.log('Form data before formatting:', formData);
      
      if (!isEmail) {
        formData.MobileNumber = `${countries[selectedCountry].code}${formData.MobileNumber}`;
        console.log('Formatted MobileNumber:', formData.MobileNumber);
      }
      
      const registrationData = {
        fullName: formData.fullName,
        password: formData.password,
        location: formData.location
      };
      
      if (isEmail) {
        registrationData.email = formData.email;
      } else {
        registrationData.MobileNumber = formData.MobileNumber;
      }
      
      console.log('Registration data:', registrationData);
      
      const resp = await axios.post('https://mabkart.org/user/register', registrationData);
      console.log('Server response:', resp);
      
      if (!resp.data.status) {
        // Handle cases where registration failed
        console.log('Registration failed:', resp.data.message);
        
        if (resp.data.message === "Email Already Exist") {
          alert('Email already exists, Login.');
          setIsReg(false);
        } else if (resp.data.message === "Mobile Number Already Exist") {
          alert('Mobile number already exists, Login.');
          setIsReg(false);
        }
      } else {
        // Handle successful registration
        console.log('Registration successful:', resp.data.data);
        
        const { _id } = resp.data.data;  // Extract userId (_id) from the response data
        localStorage.setItem('master-app', JSON.stringify({ userId: _id }));
        
        swal.fire({
          title: 'Success',
          text: 'Registered successfully.',
          icon: 'success',
        }).then(() => {
          setTimeout(() => {
            if (loginStatus) {
              navigate(`${loginStatus}`);
            } else {
              navigate(`/?id=${_id}`);
            }
          }, 200);
        });
      }
    } catch (error) {
      console.error('Registration error:', error);
    }
  };
  
  
  
  

const checkUserExistence = async () => {
  if (!((formData.MobileNumber||formData.email)&&formData.password)) {
    alert('Please fill in all required fields.');
    return;
  }
  // Validate mobile number
  if (formData.MobileNumber.length <= 6 && !isEmail) {
    alert('Mobile number too short');
    return;
  }
   // Validate email
      if (!emailRegex.test(formData.email) &&isEmail ) {
        alert('Invalid email format');
        return;
      }
  
      
  try {
    if (!isEmail) {
      formData.MobileNumber = `${countries[selectedCountry].code}${formData.MobileNumber}`;
    }
    const fdata = isEmail 
      ? { email: formData.email, password: formData.password }
      : { MobileNumber: formData.MobileNumber, password: formData.password };

    const response = await axios.post('https://mabkart.org/user/login', fdata);


    if (response.data && response.data.status === false) {
      const errorMessage = response.data.message.trim();
      if (errorMessage === 'email or password is not correct') {
        setIsReg(false);
      setShowReg(true);
        // Reset formData fields
      }
       else if (errorMessage === 'invalid password') {
        alert('Invalid Password');
      }
    } else if (response.data && response.data.status === true) {
      const { userId, token } = response.data.data;

     localStorage.setItem('master-app', JSON.stringify({ userId, token }));
      swal.fire({
        title: 'Success',
        text: 'Logged in successfully.',
        icon: 'success',
      }).then(() => {
        setTimeout(() => {
          if(loginStatus){
            navigate(`${loginStatus}`);
          }else{
            navigate(`/?id=${userId}`);
          }
        }, 200);
      });
    } else {
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404 && error.response.data && error.response.data.message==='email  or password is not correct') {
        if (error.response.data.message.trim() === 'email  or password is not correct') {
          setIsReg(false);
          setShowReg(true);
        }
      } else {
        alert('Invalid Password');
        reset();
      }
    } else if (error.request) {
      alert('No response received from the server. Please try again later.');
      reset()
    } else {
      alert('An unexpected error occurred. Please try again later.');
      reset()
    }
  }
};

  
  
  

  // State for reset password modal
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetFormData, setResetFormData] = useState({
    MobileNumber: '',
          email: '',
    newPassword: '',
    token: ''
  });
  const [isResetSubmitted, setIsResetSubmitted] = useState(false);
  
  const handleResetInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'credential') {
      const isNumber = /^\d+$/.test(value);
      setShowCountryCode2(isNumber);
      setIsEmail2(!isNumber);

      if (isNumber) {
        setResetFormData({
          ...resetFormData,
          MobileNumber: value,
          email: ''
        });
      } else {
        setResetFormData({
          ...resetFormData,
          email: value,
          MobileNumber: ''
        });
      }
    }else{
    setResetFormData({
      ...resetFormData,
      [name]: value
    });}
  };

  const handleResetSubmit = async () => {
    if (!(resetFormData.MobileNumber || resetFormData.email)) {
      alert('Please fill in all required fields.');
      return;
    }
    try {
      if (!isEmail2) {
        resetFormData.MobileNumber = `${countries[selectedCountry2].code}${resetFormData.MobileNumber}`;
      }
      const response = await axios.post('https://mabkart.org/user/reset', 
        isEmail2 
          ? { email: resetFormData.email } 
          : { MobileNumber: resetFormData.MobileNumber }
      );
      if (response.data.message === 'A reset token has been sent to your email/mobile number') {
        swal.fire({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
        });
        setIsResetSubmitted(true);

      } else {
        swal.fire({
          title: 'Error',
          text: response.data.message,
          icon: 'error',
        });
        setIsResetSubmitted(false);
      }
    } catch (error) {
      swal.fire({
        title: 'Error',
        text: error.response ? error.response.data.message : 'An unexpected error occurred.',
        icon: 'error',
      });
      setIsResetSubmitted(false);
    }
  };

  const handleConfirmReset = async () => {
    if (!((resetFormData.MobileNumber || resetFormData.email)&&resetFormData.token&&resetFormData.newPassword)) {
      alert('Please fill in all required fields.');
      return;
    }
    try {
      const response = await axios.post('https://mabkart.org/user/new-password', {
        token: resetFormData.token,
        password: resetFormData.newPassword
      });
      if (response.data.message === 'Password updated successfully') {
        swal.fire({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
        }).then(() => {
          setShowResetModal(false);
          setIsResetSubmitted(false);
          setResetFormData({
            MobileNumber: '',
            email: '',
            newPassword: '',
            token: ''
          });
        });
      } else {
        swal.fire({
          title: 'Error',
          text: response.data.message,
          icon: 'error',
        });
      }
    } catch (error) {
      swal.fire({
        title: 'Error',
        text: error.response ? error.response.data.message : 'An unexpected error occurred.',
        icon: 'error',
      });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', borderRadius: '10px', backgroundColor: '#f0f0f0' }}>
      <div style={{ fontSize: '1vw', width: '80%', height: '80%', display: 'flex', backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
        <div style={{ width: '30%', backgroundColor: '#80ff80', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '10px 0 0 10px' }}>
        <Button variant="primary" onClick={() => navigate(-1)}>
              Back
            </Button>
          <span style={{ textAlign:'center', fontSize: '25px', fontWeight: 'lighter' }}>Login<br/>or<br/>Signup</span>
          <img src="Jack.png" alt="Placeholder" style={{ marginTop: '10px', width: '31vw', borderRadius: '50%' }} />
        </div>
        <Modal show={showReg} backdrop='static' keyboard={false} onHide={() => setShowReg(false)}>
        <Modal.Body>
        User does not exist, Register.
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={reset}>
            Register
            </Button>
        </Modal.Footer>
      </Modal>
        <div style={{ width: '70%' }}>
          <Form key={seed}>
            <Form.Group controlId={showCountryCode ? 'formPlaintextPassword' : 'formPlaintextEmail'}>
              {showCountryCode && (
                <select
                  style={{ marginLeft: '20px', width: '70%', marginTop: '20px', height: '5vh', borderRadius: '5px',fontSize:'1.5vh' }}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <option value="" disabled>Select a country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={index}>
                      {`${country?.name} (${country?.code})`}
                    </option>
                  ))}
                </select>
              )}
              <Form.Control
                style={{ marginTop: '20px', marginLeft: '20px', width: '70%', height: '5vh', borderRadius: '5px' }}
                onChange={handleInputChange}
                name="logincredential"
                placeholder="Email/Mobile Number"
              />
              <div style={{display:'flex'}}>
            <Form.Control
        style={{
         marginTop: '20px', marginLeft: '20px', width: '70%', height: '5vh', borderRadius: '5px'
        }}
        onChange={handleInputChange}
        name="password"
        placeholder="Password"
        type={showPassword ? 'text' : 'password'}
      />
      <Button
        onClick={toggleShowPassword}
        style={{
          marginLeft: '10px',
          backgroundColor:'white',
          height:'100%',
          borderRadius:'5px',
          marginTop:'20px',
          alignItems:'center',
          justifyContent:'center'
        }}
      >
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} style={{color:'black'}} />
      </Button></div>
            </Form.Group>
            {!isReg && (
              <>
                <Form.Group controlId="formPlaintextName">
                  <Form.Control
                    style={{ marginTop: '20px', marginLeft: '20px', width: '70%', height: '5vh', borderRadius: '5px' }}
                    value={formData.fullName}
                    onChange={handleInputChange}
                    name="fullName"
                    type="text"
                    placeholder="Enter your name"
                  />
                </Form.Group>
                <Form.Group controlId="formPlaintextLocation">
                  <Form.Control
                    style={{marginTop: '20px', marginLeft: '20px', width: '70%', height: '5vh', borderRadius: '5px' }}
                    value={formData.location}
                    onChange={handleInputChange}
                    name="location"
                    type="text"
                    placeholder="Enter your city"
                  />
                </Form.Group>
              </>
            )}
              <Button
              style={{ width: '50%', marginLeft: '20px',marginTop:'20px' }}
              type="button"
              onClick={doAction}
              className='btns'
              variant="success"
            >
              {isReg ? 'Login' : 'Register'}
            </Button>
           

            
      
          
            
              {isReg && (
  <Button
    style={{ width: '50%', marginLeft: '20px', marginTop: '10px' }}
    type="button"
    onClick={() => setIsReg(false)}
    className='btns'
    variant="primary"
  >
    Not Registered? Register
  </Button>
)}
{!isReg && (
  <Button
    style={{ width: '50%', marginLeft: '20px', marginTop: '10px' }}
    type="button"
    onClick={() => setIsReg(true)}
    className='btns'
    variant="primary"
  >
    Already Registered? Login
  </Button>
)}
              <Button
              style={{  width: '50%', marginLeft: '20px',marginTop:'10px' }}
              type="button"
              onClick={() => setShowResetModal(true)}
              variant="danger"

            >
              Forgot Password?
            </Button>
              <h6 style={{  width: '50%', marginLeft: '20px',marginTop:'10px',textAlign:'center' }}> Or</h6>
           <Button
  style={{ width: '50%', marginLeft: '20px', marginTop: '10px' }}
  type="button"
  variant="btn btn-outline-primary"
  onClick={() => window.location.href = '/user/auth/google'}
>
  Login/Register With Google
</Button> 
          </Form>
        </div>
      </div>

      {/* Reset Password Modal */}
      <Modal show={showResetModal} backdrop='static' keyboard={false} onHide={() => setShowResetModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="resetCredential" >
          <Form.Label>Email/Mobile Number</Form.Label>

              {showCountryCode2 && (
                <select
                  style={{ marginBottom: '20px', height: '5vh',  borderRadius: '5px',fontSize:'1.5vh' ,width:'100%'}}
                  value={selectedCountry2}
                  onChange={handleCountryChange2}
                >
                  <option value="" disabled>Select a country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={index}>
                      {`${country?.name} (${country?.code})`}
                    </option>
                  ))}
                </select>
              )}
              <Form.Control
                value={resetFormData.credential}
                onChange={handleResetInputChange}
                name="credential"
                placeholder="Enter your email or mobile number"
                
              /></Form.Group>
        
            {isResetSubmitted && (
              <>
                <Form.Group controlId="formToken">
                <Form.Label>Reset Token</Form.Label>
                <Form.Control type="text" name="token" placeholder="Enter reset token" value={resetFormData.token} onChange={handleResetInputChange} />
              </Form.Group>
                <Form.Group controlId="newPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your new password"
                    name="newPassword"
                    value={resetFormData.newPassword}
                    onChange={handleResetInputChange}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={isResetSubmitted ? handleConfirmReset : handleResetSubmit}>
              {isResetSubmitted ? 'Confirm Reset' : 'Send Reset Token'}
            </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;
