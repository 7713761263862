import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaSquareXTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa6';

const Footer = () => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsOfService, setShowTermsOfService] = useState(false);

  const openPrivacyPolicy = () => {
    setShowPrivacyPolicy(true);
  };

  const closePrivacyPolicy = () => {
    setShowPrivacyPolicy(false);
  };

  const openTermsOfService = () => {
    setShowTermsOfService(true);
  };

  const closeTermsOfService = () => {
    setShowTermsOfService(false);
  };

  return (
    <footer style={{ position: 'relative', textAlign: 'center' }}>
      <div className="follow-us">
        <h2 style={{fontWeight:'lighter',fontSize:'3vw'}}>Follow us</h2>
        <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://www.facebook.com/people/My-Adventure-Book/100076280179861/"><FaFacebook style={{ color: '#3b5998' }}/></a>
        &nbsp;&nbsp;
        <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://www.instagram.com/mab_appforkids/"><FaInstagram style={{ color: '#E4405F' }}/></a>
        &nbsp;&nbsp;
        <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://twitter.com/MAB_app"><FaSquareXTwitter style={{  }}/></a>
        &nbsp;&nbsp;
        <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://www.linkedin.com/company/orion-eduverse/"><FaLinkedin style={{ color: '#0077B5' }}/></a>
        &nbsp;&nbsp;
        <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://www.youtube.com/channel/UCbLe7F1uCrJBZsuR_TjKZ6g"><FaYoutube style={{ color: '#FF0000' }}/></a>
      </div>
      <div className="contact">
    <hr style={{width:'75%'}}></hr>
        <p>Email: <a href="mailto:contact@myadventurebook.org">mabkart777@gmail.com</a> <br/> Phone: <a href="tel:+919380706694">  +919677028480</a></p>
        <p></p>
      </div>
      <div className="terms">
      <p style={{fontWeight:'lighter'}}>Read our <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={openPrivacyPolicy}>Privacy policy</span> and <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={openTermsOfService}>Terms & Conditions</span></p>
      </div>
      {showPrivacyPolicy && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={closePrivacyPolicy}>

          <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', overflowY: 'auto', maxHeight: '80vh', maxWidth: '80vw' ,textAlign:'left'}}>
          <button onClick={closePrivacyPolicy}>Close</button>
<div>
          <h2 style={{fontSize:'1.vw'}}>Privacy Policy for Mab Kart</h2>
      <p style={{}}>This Privacy Statement has been created in order to demonstrate our firm commitment to your privacy. This document discloses the information gathering and dissemination practices of the Mab Kart section of My Adventure Book website.</p>   
         <p style={{}}>Information are Not Automatically Logged:</p>
      <p style={{fontSize:'1vw'}}><b>Information are Not Automatically Logged:</b></p>
      <p style={{}}>No personal or confidential information is collected automatically. We do not collect your data unless you create an account with us.</p>
<p style={{fontSize:'1vw'}}><b>Cookies:</b></p>
<p style={{}}>We do not collect cookies data. Cookies are enabled by most browsers by default. You can disable these in your browsers easily.</p>
<p style={{fontSize:'1vw'}}><b>Email, SMS communications:</b></p>
<p style={{}}>When you sign up with us or place orders we will send you updates of the order and shipping details by email or sms. These messages help you with timely information about your order so that you are updated about your purchase. We will occasionally send you emails with information on new offers or products that may be of interest to you. If you do not wish to receive these emails you can unsubscribe at any time by emailing us to stop. We will not spam you with any other advertisements or information. Your email or phone numbers are not shared with third-parties for them to send third-party information or mailers to you.</p>
<p style={{fontSize:'1vw'}}><b>Member Information:</b></p>
<p style={{}}>We completely respect your privacy and dislike spam as much as you do. Your name, address, phone number and email addresses collected while joining the website are never revealed to a third-party or marketing agency for promotion of third-party content. You can be assured no spam will reach you because of us.</p>
<p style={{fontSize:'1vw'}}><b>Instagram / Facebook / Google Login Information:</b></p>
<p style={{}}>Some personalised products may provide the option of logging in through Facebook, Instagram or Google. This login happens on APIs provided by the respective companies. Data, if any, that is collected is explicitly mentioned as you login. Some data may be used to create the product which you logged in for. No information is permanently stored or shared with third-parties.</p>
<p style={{fontSize:'1vw'}}><b>External Links:</b></p>
<p style={{}}>This site may contain links to other sites. We are not responsible for the privacy practices or the content of any websites whose links are provided on this website or those which link to us.</p>
<p style={{fontSize:'1vw'}}><b>Contacting the Web Site:</b></p>
<p style={{}}>If you have any questions about this privacy statement, the practices of this site, or your dealings with this Web site, please contact us using the details provided in the website.</p>

          
</div>






            <button onClick={closePrivacyPolicy}>Close</button>
          </div>
        </div>
      )}
      {showTermsOfService && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={closeTermsOfService}>
          <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', overflowY: 'auto', maxHeight: '80vh', maxWidth: '80vw', textAlign:'left' }}>
          <button onClick={closeTermsOfService}>Close</button>
<h2>Terms Of Use for MAB KART</h2>
            <p>
<p>Effective 15th July 2024</p>

<p>By using or by purchasing any products or experience packages at My Adventure Book website for Mab Kart products you are bound by the following terms and conditions that are applicable as a contract between you and MAB KART.</p>
<p><b>Refunds and Cancellations and Shipping Policy</b></p>
<p>Cancellation request time: within 12 hours from time of order
Refund Request time: Within 24 hours from time of order
Refund Processing Time:- 16 to 30 days
Shipping time: 1 to 2 days
The above timings are approximation. It may change for unforeseen circumstances.</p>
<p>Mab Kart (business Name: SAURAV KUMAR) works with a variety of partners in India to provide the vast range of items and experiences to you. Our policies on refunds, exchange and cancellations are dependent on and restricted by the policies of these partners.

 
</p>
<p><b>PRODUCTS</b></p>

<p><b>Shipping process :</b> Shipping process starts on the same day if the order is placed before 2pm. If the order is placed after 2pm the shipping process starts the next day.</p>

<p><b>Delivery:</b> Within India, we expect the shipment to arrive in 7 to 10 days depending upon the courier service. For outside India, the delivery may take approx. 15 to 20 days depending upon the location. Please note that we use the one of the most reliable courier services for the. shipment of your orders. 
<p>Shipping charges for returns incurred by customers will not be refunded. This will be cost to the customer</p>
<p>All products shipped carry their original manufacturer's warranty as applicable. If you feel the product is defective or incomplete please contact us immediately and we will work out a solution to your satisfaction</p>

<p><b>Return policy: </b></p>

<p>Products can be returned within 10 days in case of defects or functional problems. You will need to send these back to us to get credit for the amount paid. Returns are not processed for any products that are tampered with, in used condition, or with damaged packaging etc. Personalised items are not covered under refunds unless there is transit damage or other defects. If the address is incorrect or the recipient is not available we will not be able to deliver again. No refunds are possible in such cases. Once the case for the returned item after internal inspection is found to be genuine and satisfactory, refunds will be initiated within 15 days from the date of receiving the returned item. </p>
<p>Refunds are always made to the person who made the purchase, and if originally purchased by credit or debit card, the refund will be credited by our payment partner Razorpay as per their available mode of transfers.</p>
<p>Shipping charges for returns incurred by customers will not be refunded. This will be cost to the customer.</p>
<p><b>International Shipping:</b></p>

<p>We ship some products outside India. As per international shipping regulations there may be Customs Duty or other taxes that may be levied by the Governments of the consignee countries. In such cases these duties need to be paid by the consignee. We are not aware of regulations for different products in different countries and cannot predict this at the time of purchase. Delivery times are only estimates and we do not provide guarantees for these because of a variety of factors involved in international shipping. Due to the nature of these shipments we are unable to offer any refunds for the same.

</p>

<p><b>Delivery</b></p>

<p>All gifts and experiences can be sent to you, directly to the recipient, or to another address at your request. Please make sure the address that you provide before making the final purchase is the intended address. You can always update the address before making the purchase. </p>

<p>Please ensure the correct delivery address with phone number in your order. We are not responsible for any delays or non-delivery that results from incorrect addresses, or in case premises are locked. </p>

<p>If a parcel goes astray in delivery, we will provide a duplicate free of charge. Please contact us as soon as possible for this.</p>

<p>Items will be delivered on or before the actual delivery date required.

</p>

<p>There are no guarantees for courier shipments - we try and deliver to you as required however in various conditions beyond our control there could be delays.</p>

<p><b>Experience Description:</b></p>

<p>The information provided by Mab Kart on website and social media channels are correct at the time of print. However, the content or format of experiences may change slightly during the year. Current details of each gift are available on our website.</p>

<p><b>Exchange Rates:</b></p>

<p>Our base pricing is in Indian Rupees however you may use a credit card from any other country to pay for products. The prices displayed at the time of payment are as per Razorpay exchange rates policies and governed by them. Mab Kart does not decide the exchange rate variation of any sort whatsoever.</p>


<p><b>Complaints & Liability:</b></p>
<p>We welcome your feedback on all of our gifts and experiences. If you have a problem or complaint on the day, please bring it to our attention via email to mabkart777@gmail.com as soon as possible, so we have a chance to put matters right. If your problem is not resolved on the day, please allow us some time and we will be happy to take the matter up with the supplier.</p>
<p>Mab Kart, its associated partners, suppliers and their employees or Directors are not responsible for claims arising from personal injury, death due to the negligence, delays in implementation or any other losses of any kind that you may face while using the products purchased. </p>
</p>
<p><b>Copyright & Others:</b></p>
<p>All prices quoted in our promotional literature and on our website do not include taxes and these may be charged extra as you check out. We reserve the right to increase the price of any item without notice.

</p>
<p>The text, illustrations and images used on My Adventure Book website, promotional literature, EDMs are protected by copyright and must not be copied in whole or in part without the express permission of Mab Kart.</p>
<p>Mab Kart reserves the right to change these terms from time to time without reference to customers. These terms and conditions do not affect your statutory rights as a consumer, and are governed by and in accordance with Indian law. The purchaser and Mab Kart agree that any problems fall under the jurisdiction of the Ranchi courts.</p>

            <button onClick={closeTermsOfService}>Close</button>
            </p>
          </div>
          
        </div>
      )}
    </footer>
  );
}

export default Footer;
