import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert2';
import logo from './logo.png'; // Import your image
import PaymentComponent from './payment.jsx';
// 22
const Cart = () => {
  const navigate = useNavigate();

  const handleHover = (e) => {
    const box = e.target;
    box.style.transform = 'translateX(-5px)';
    setTimeout(() => {
      box.style.transform = 'translateX(5px)';
      setTimeout(() => {
        box.style.transform = 'translateX(-3px)';
        setTimeout(() => {
          box.style.transform = 'translateX(3px)';
          setTimeout(() => {
            box.style.transform = 'translateX(-2px)';
            setTimeout(() => {
              box.style.transform = 'translateX(2px)';
              setTimeout(() => {
                box.style.transform = 'translateX(0)';
              }, 100);
            }, 100);
          }, 100);
        }, 100);
      }, 100);
    }, 100);
  };

  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cartId, setCartId] = useState('');
  const [userData, setUserData] = useState(null); // State to store user data
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [changeAddressOpen, setChangeAddressOpen] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);
  const [newAddress, setNewAddress] = useState({
    name: '',
    street: '',
    city: '',
    pincode: '',
    houseNo: '',
    landmark:'',
    state:'',
    alternateMobileNumber:''

  });
  const storedData = JSON.parse(localStorage.getItem('master-app'));


  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const storedData = JSON.parse(localStorage.getItem('master-app'));
        const userId = storedData ? storedData.userId : null; // Retrieve user ID from localStorage
                const response = await axios.get(`https://mabkart.org/cart/getcart/${userId}`);

        if (response.data.status) {
          setCartItems(response.data.data.items);
          setTotalAmount(response.data.data.totalPrice);
          setCartId(response.data.data._id);
        } else {
          console.error('Failed to fetch cart items');
        }
      } catch (error) {
        console.error('Error fetching cart items:', error);
      }
    };

    fetchCartItems();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedData = JSON.parse(localStorage.getItem('master-app'));
        const userId = storedData ? storedData.userId : null; // Retrieve user ID from localStorage
                const response = await axios.get(`https://mabkart.org/user/get/${userId}`);

        if (response.data.status) {
          setUserData(response.data.data);
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [reloadKey]);

const handleRemoveItem = async (productId) => {
  // Show a confirmation dialog
  const isConfirmed = window.confirm('Are you sure you want to remove this item from the cart?');

  // If the user confirms, proceed with the removal
  if (isConfirmed) {
    try {
      const storedData = JSON.parse(localStorage.getItem('master-app'));
      const userId = storedData ? storedData.userId : null; // Retrieve user ID from localStorage
            const response = await axios.put(`https://mabkart.org/cart/updatecart/${userId}`, {
        productId: productId,
        cartId: cartId,
        removeProduct: 1,
      });

      if (response.data.status) {
        // Delayed refresh after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 200);
      } else {
        console.error('Failed to remove cart item');
      }
    } catch (error) {
      console.error('Error removing cart item:', error);
    }
  }
};


const handleCheckout = async () => {
    setLightboxOpen(true); // Open the lightbox overlay for checkout
  };

  const handleChangeAddress = () => {
    setChangeAddressOpen(true); // Open the lightbox overlay for changing address
  };

  const handleCloseLightbox = () => {
    setLightboxOpen(false); // Close the lightbox overlay
  };

  const handleCloseLightbox2 = () => {
    setChangeAddressOpen(false); // Close the change address lightbox overlay
  };
  const handleAddressFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'pincode' && !/^\d*$/.test(value)) return; // Ensure pincode is numeric
    if (name === 'alternateMobileNumber' && !/^\d*$/.test(value)) return; // Ensure alt no. is numeric

    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  
  const handleSubmitAddress = async (e) => {
    e.preventDefault();
    const { street, city, pincode, houseNo,    landmark,    state,    alternateMobileNumber, name } = newAddress;
    if (!street || !city || !pincode || !houseNo || !landmark || !state || !alternateMobileNumber  || !name) {
      swal.fire({
        title: 'Error',
        text: 'All address fields must be filled out.',
        icon: 'error',
      });
      return;
    }
    try {
      const storedData = JSON.parse(localStorage.getItem('master-app'));
      const userId = storedData ? storedData.userId : null; // Retrieve user ID from localStorage


            const response = await axios.put(`https://mabkart.org/user/updating/${userId}`, {
        address: newAddress,
      });
  
      if (response.data.message) {
        swal.fire({
          title: 'Success',
          text: 'Address updated successfully!',
          icon: 'success',
        }).then(() => {
          setChangeAddressOpen(false);
          setTimeout(() => {
            setReloadKey(prevKey => prevKey + 1);
          }, 100);
        });
      } else {
        console.error('Failed to update address');
      }
    } catch (error) {
      console.error('Error updating address:', error);
    }
  };
  
  const handlelogin=()=>{
    let currentRoute = window.location.pathname;
    console.log(currentRoute);
    localStorage.setItem("loginstatus", JSON.stringify(currentRoute));
    navigate("/login");

  }

  const statesAndUTs = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
    'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka',
    'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram',
    'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana',
    'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands',
    'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep', 'Delhi',
    'Puducherry', 'Ladakh', 'Jammu and Kashmir'
  ];


  const handleClick = async () => {
    try {
      // Send a logout request to the API
      await axios.post('https://mabkart.org/user/logout', {});

      // Remove the user ID from local storage
      localStorage.removeItem('master-app');

      // Navigate to the home page
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
      // Optionally handle the error, e.g., show a message to the user
    }
  };
  return (
    <div className="CartPage" style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
           <header style={{ backgroundColor: 'white', color: '#333', padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px',gap:'1vw'}}>
        <Link style={{textDecoration:'None', textAnchor:'None'}} to="/">
          <div
            style={{
              width: '8vw',
              height: '8vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }} />
          </div>
          </Link>
          <Link style={{textDecoration:'None', textAnchor:'None'}} to="/">
          <div
            style={{
              width: '16vw',
              height: '8vw',
              backgroundColor: '#CFF27E', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s ease-in-out',
              borderRadius:'10px',
              fontSize:'1.5vw'

            }}
            onMouseEnter={handleHover}
            onMouseLeave={(e) => {
              e.target.style.transform = 'translateX(0)';
            }}
          > 
          Merchandise
          </div>
          </Link>
          {!localStorage.getItem("master-app") ? (
             <div
              onClick={handlelogin}
               style={{
                 width: '8vw',
                 height: '8vw',
                 backgroundColor: '#ADF7B6', 
                 cursor: 'pointer',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center',
                 transition: 'transform 0.2s ease-in-out',
                 borderRadius:'10px',
                 fontSize:'1.5vw'
   
               }}
               onMouseEnter={handleHover}
               onMouseLeave={(e) => {
                 e.target.style.transform = 'translateX(0)';
               }}
             > 
             Login
             </div>
          ) :
           (
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#ADF7B6', 
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius:'10px',
                fontSize:'1.5vw'
  
              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
              onClick={handleClick}
            > 
            Logout
            </div>
            
          )}
               {localStorage.getItem("master-app") ? (
          <Link style={{ textDecoration: 'none' }} to="/cart">
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#e5b25d',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius: '10px',
                fontSize: '1.5vw'
              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
            >
              Cart
            </div>
          </Link>):''}
          {localStorage.getItem("master-app") ? (

          <Link style={{ textDecoration: 'None' }} to="/dashboard">
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#90D7FF',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius: '10px',
                fontSize: '1.5vw'

              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
            >
              Dashboard
            </div>
          </Link>):''}
          

        </div>
        

      </header>
      <div style={{ padding: '20px' }}>
        {cartItems.length > 0 ? (
          <div style={{overflowX:'scroll'}}>
            <table style={{ width: '100%', borderCollapse: 'collapse' ,justifyContent:'center',alignItems:'center',}}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #ccc', padding: '10px' }}>Product</th>
                  <th style={{ border: '1px solid #ccc', padding: '10px' }}>Price</th>
                  <th style={{ border: '1px solid #ccc', padding: '10px' }}>Quantity</th>
                  <th style={{ border: '1px solid #ccc', padding: '10px' }}>Total</th>
                  <th style={{ border: '1px solid #ccc', padding: '10px' }}>Actions</th>
                </tr>
              </thead>
              <tbody >
                {cartItems.map(item => (
                  <tr key={item._id} style={{}}>
                    <td style={{ border: '1px solid #ccc', padding: '10px', display: 'flex', alignItems: 'center' }}>
                      <img src={`https://mabkart.org${item.productId.image[0]}`} alt={item.productId.title} style={{ width: '100px' }} />
                      <div>
                      <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>{item.productId.title}</span>

                      {item.productId.pictureUpload && (
                        <p style={{ color: 'green' }}>Customisation image uploaded</p>
                      )}
                      {item.productId.namePrint && (
                        <p style={{ color: 'green' }}>Customisation name uploaded</p>
                      )}
                      </div>
                    </td>
                    <td style={{ border: '1px solid #ccc', padding: '10px' ,textAlign:'center'}}>₹{item.productId.price}</td>
                    <td style={{ border: '1px solid #ccc', padding: '10px' ,textAlign:'center'}}>{item.quantity}</td>
                    <td style={{ border: '1px solid #ccc', padding: '10px' ,textAlign:'center'}}>₹{item.productId.price * item.quantity}</td>
                    <td style={{ border: '1px solid #ccc', padding: '10px' ,textAlign:'center'}}>
                    <button onClick={() => handleRemoveItem(item.productId._id)}>Remove</button></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h2>Total Amount: ₹{totalAmount}</h2>
            <button onClick={handleCheckout} style={{ padding: '10px 20px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '5px' }}>Proceed to Checkout</button>
          </div>
        ) : (
          <p>Your cart is empty.</p>
        )}
      </div>
  {/* Lightbox overlay for Checkout */}
  {lightboxOpen && (
        <div key={reloadKey} className="lightbox" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          
          <div className="lightbox-content" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', maxWidth: '400px' }}>
          <button onClick={handleCloseLightbox} style={{ padding: '5px 10px', backgroundColor: '#ff474c', color: 'black', border: '1px solid black', borderRadius: '5px' }}>&times;</button>

            <h2>Current Address:</h2>
            {userData && (
              <div>
                <p><strong>Receiver's Name:</strong> {userData.address.name}</p>
                <p><strong>House No.:</strong> {userData.address.houseNo}</p>

                <p><strong>Street:</strong> {userData.address.street}</p>
                <p><strong>City:</strong> {userData.address.city}</p>
                <p><strong>Landmark:</strong> {userData.address.landmark}</p>

                <p><strong>Pincode:</strong> {userData.address.pincode}</p>
                <p><strong>State:</strong> {userData.address.state}</p>
                <p><strong>Alternative Mobile No.:</strong> {userData.address.alternateMobileNumber}</p>


              </div>
            )}
            <div style={{display:'flex'}}>
            <button onClick={handleChangeAddress} style={{ padding: '10px 20px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px', marginRight: '10px' }}>Update Address</button>
            {userData.address.pincode? 
             ( <PaymentComponent  bookId={cartId} userId={storedData.userId} />)
             : (<button>Update address before ordering</button>)}
            </div>
          </div>
        </div>
      )}

      {/* Lightbox overlay for Change Address */}
      {changeAddressOpen && (
        <div className="lightbox" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="lightbox-content" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', maxWidth: '400px' }}>
          <button onClick={handleCloseLightbox2} style={{ padding: '5px 10px', backgroundColor: '#ff474c', color: 'black', border: '1px solid black', borderRadius: '5px' }}>&times;</button>

            <h2>Change Address</h2>
            <form onSubmit={handleSubmitAddress}>
            <label>
                <input type="text" name="name" placeholder="Enter Receiver's Name" value={newAddress.name} onChange={handleAddressFormChange} required />
              </label>
            <label>
                <input type="text" name="houseNo" placeholder='Enter House No.' value={newAddress.houseNo} onChange={handleAddressFormChange} required />
              </label>
              <label>
                <input type="text" name="street" placeholder='Enter Street' value={newAddress.street} onChange={handleAddressFormChange} required />
              </label>
              <label>
                <input type="text" name="city" placeholder='Enter City' value={newAddress.city} onChange={handleAddressFormChange} required/>
              </label>
              <label>
                <input type="text" name="landmark" placeholder='Enter Landmark' value={newAddress.landmark} onChange={handleAddressFormChange} required />
              </label>
              <label>
                <input type="text" name="pincode" placeholder='Enter Pincode' value={newAddress.pincode} onChange={handleAddressFormChange} required/>
              </label>

              <label>
      <select 
        name="state" 
        value={newAddress.state} 
        onChange={handleAddressFormChange} 
        required
      >
        <option value="" disabled>Select State/UT</option>
        {statesAndUTs.map((state, index) => (
          <option key={index} value={state}>{state}</option>
        ))}
      </select>
    </label>

              <label>
                <input type="text" name="alternateMobileNumber" placeholder='Enter Alternate Mobile Number' value={newAddress.alternateMobileNumber} onChange={handleAddressFormChange} required />
              </label>
              <button type="submit" style={{ padding: '10px 20px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px', marginRight: '10px' }}>Update Address</button>
            </form>
          </div>
        </div>
      )}
      
    </div>
  );
};

export default Cart;
