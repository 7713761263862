import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Footer from '../footer.js';
import logo from './logo.png'; // Import your image
import { useNavigate } from "react-router-dom";
import cup from './cup.png'
import key from './key.png'
import bottle from './bottle.png'
import note from './note.png'
import tee from './tee.png'
import badge from './badge.png'
import comic from './comic.png'
import bottles from './bottles.png'
import tshirts from './tshirts.jpeg'
import keys from './keys.jpg'
import cups from './cups.jpeg'
import Badges from './mabbadges.png'
import cusion from './cushion.png'
import axios from 'axios';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';
const Merchandise = () => {
  const merchandiseData = [
    { id: 1, name: 'Coffee Mugs', image: cups, link: '/coffee-mugs' },
    { id: 2, name: 'Key Chains', image: keys, link: '/key-chains' },
    { id: 3, name: 'Water Bottles', image: bottles, link: '/water-bottles' },
    // { id: 4, name: 'Notebooks', image: note, link: '/notebooks' },
    { id: 5, name: 'T-Shirts', image: tshirts, link: '/t-shirts' },
    { id: 6, name: ' Badges', image: Badges, link: '/personal-badges' },
    // { id: 7, name: 'Comic Books', image: comic, link: '/comic-books' }
    { id:7, name:'Cushions', image: cusion, link:'/Cushions'}
  ];
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const handleHover = (e) => {
    const box = e.target;
    box.style.transform = 'translateX(-5px)';
    setTimeout(() => {
      box.style.transform = 'translateX(5px)';
      setTimeout(() => {
        box.style.transform = 'translateX(-3px)';
        setTimeout(() => {
          box.style.transform = 'translateX(3px)';
          setTimeout(() => {
            box.style.transform = 'translateX(-2px)';
            setTimeout(() => {
              box.style.transform = 'translateX(2px)';
              setTimeout(() => {
                box.style.transform = 'translateX(0)';
              }, 100);
            }, 100);
          }, 100);
        }, 100);
      }, 100);
    }, 100);
  };
  const navigate = useNavigate();

  const handlelogin=()=>{
    let currentRoute = window.location.pathname;
    console.log(currentRoute);
    localStorage.setItem("loginstatus", JSON.stringify(currentRoute));
    navigate("/login");

  }

  const handleClick = async () => {
    try {
      // Send a logout request to the API
      await axios.post('https://mabkart.org/user/logout', {});

      // Remove the user ID from local storage
      localStorage.removeItem('master-app');

      // Navigate to the home page
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
      // Optionally handle the error, e.g., show a message to the user
    }
  };
  
  const itemStyle = {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '20px',
    width: '20vw',
    height:'20vw',
    textAlign: 'center',
    transition: 'transform 0.3s',
    alignItems:'center',
    justifyContent:'center',
    marginLeft:'10px',
    marginRight:'10px',
    marginTop:'10px',
    marginBottom:'10px'
  };

  const imgStyle = {
    maxHeight: '15vw',
    borderRadius: '5px',
  };

  const listStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.transform = 'scale(1.1)';
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.transform = 'scale(1)';
  };

  useEffect(() => {
    const checkAndFetchData = async () => {
      const storedData = localStorage.getItem('master-app');

      if (!storedData) {
        try {
          const response = await axios.get('https://mabkart.org/user/success');
          
          console.log('API Response:', response);

          if (response.data && response.data.data) {
            const { _id } = response.data.data;

            // Store the user ID in local storage
            localStorage.setItem('master-app', JSON.stringify({ userId: _id }));

            // Refresh the page after storing the userId
            window.location.reload();
          } else {
            console.error('Unexpected response structure:', response.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        console.log('User ID already stored:', JSON.parse(storedData));
      }
    };

    checkAndFetchData();
  }, []); // Empty dependency array ensures this runs only once on mount


  return (
      <div className="App" style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
          <header style={{ backgroundColor: 'white', color: '#333', padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px',gap:'1vw'}}>
        <Link style={{textDecoration:'None', textAnchor:'None'}} to="/">
          <div
            style={{
              width: '8vw',
              height: '8vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }} />
          </div>
          </Link>
          <Link style={{textDecoration:'None', textAnchor:'None'}} to="/">
          <div
            style={{
              width: '16vw',
              height: '8vw',
              backgroundColor: '#CFF27E', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s ease-in-out',
              borderRadius:'10px',
              fontSize:'1.5vw'

            }}
            onMouseEnter={handleHover}
            onMouseLeave={(e) => {
              e.target.style.transform = 'translateX(0)';
            }}
          > 
          Mab Kart
          </div>
          </Link>
        

          {!localStorage.getItem("master-app") ? (
             <div
             onClick={handlelogin}
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#ADF7B6', 
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius:'10px',
                fontSize:'1.5vw'
  
              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
            > 
            Login
            </div>
          ) :
           (
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#ADF7B6', 
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius:'10px',
                fontSize:'1.5vw'
  
              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
              onClick={handleClick}
            > 
            Logout
            </div>
                 
            
          )}
          
               {localStorage.getItem("master-app") ? (
          <Link style={{ textDecoration: 'none' }} to="/cart">
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#e5b25d',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius: '10px',
                fontSize: '1.5vw'
              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
            >
              Cart
            </div>
          </Link>):''}
          {localStorage.getItem("master-app") ? (

          <Link style={{ textDecoration: 'None' }} to="/dashboard">
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#90D7FF',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius: '10px',
                fontSize: '1.5vw'

              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
            >
              Dashboard
            </div>
          </Link>):''}
        

        </div>
        

      </header>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
  {/* <h1 style={{ textAlign: 'center', background: 'red', width: '600px', color: 'white', fontSize: '2.5vw',borderRadius: '10px' }}>
    Buy COOL merchandise
  </h1> */}
</div>

        <div style={{ alignItems:'center',justifyContent: 'center',textAlign:'center',display:'flex',flexDirection:'row' ,marginTop:'-3vw'}}>
        <img src="christmaslightr.png" style={{  marginRight:'auto',justifyContent:'flex-start',maxWidth:'15vw', maxHeight: '100%' }} />


       <h1 style={{marginLeft:'0vw',fontSize:'5vw',color:'#ff4433',}}>Personalized Gifts<br/><span style={{fontSize:'5vw',color:'#662288',textDecoration:'underline',}}>Re-defined</span></h1>
       <img src="christmaslight.png" style={{  marginLeft:'auto',justifyContent:'flex-end',maxWidth:'15vw', maxHeight: '100%' }} />

       
</div>
<div style={{ marginTop:'-2vw',zIndex:'-1',alignItems:'center',justifyContent: 'center',textAlign:'center',display:'flex',flexDirection:'row' }}>

<img src="stars.png" style={{  marginLeft:'0vw',justifyContent:'flex-end',maxWidth:'15vw', maxHeight: '100%' }} />


</div>
<div style={{ alignItems:'center',justifyContent: 'center',textAlign:'center',display:'flex',flexDirection:'row',marginTop:'-5vw' }}>

<img src="girlavatar.png" style={{ zIndex:'-1',marginRight:'-10vw',marginTop:'-10vw',justifyContent:'flex-end',maxWidth:'30vw', maxHeight: '100%' }} />


<div style={{ alignItems:'center',justifyContent: 'center',textAlign:'center',display:'flex',flexDirection:'row' }}>

<img src="rose.png" style={{  marginRight:'-2vw',justifyContent:'flex-end',maxWidth:'20vw', maxHeight: '100%' }} />

<img src="box.png" style={{  marginTop:'2vw',justifyContent:'flex-end',maxWidth:'10vw', maxHeight: '100%' }} />

<img src="Jack.png" style={{  marginLeft:'-2vw',justifyContent:'flex-end',maxWidth:'20vw', maxHeight: '100%' }} />
</div>
<img src="boyavatar.png" style={{  marginTop:'-10vw',zIndex:'-1',marginLeft:'-10vw',justifyContent:'flex-end',maxWidth:'30vw', maxHeight: '100%' }} />

</div>
        <div style={listStyle}>
          {merchandiseData.map(item => (
            <Link to={item.link} key={item.id} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div
                style={itemStyle}
                
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <img src={item.image} alt={item.name} style={imgStyle} />
                <h3 style={{fontSize:'2vw'}}>{item.name}</h3>
              </div>
              
            </Link>
            
          ))}
        </div>
        {/* <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} /> */}
        <Footer />
       
      </div>

  );
 
};

export default Merchandise;
