import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';


const PaymentComponent = ({ bookId, userId }) => {
  const [orderId, setOrderId] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State to control the visibility of success modal
  const navigate = useNavigate();

  const createOrder = async () => {
    if (userId) {
      try {
        const response = await axios.post(`https://mabkart.org/order/createOrder/${bookId}`);
        setOrderId(response.data.id);
        openRazorpay(response.data.id);
      } catch (error) {
        console.error('Error creating order:', error);
      }
    } else {
      navigate('/login');
    }
  };

  const purchaseBook = async () => {
    try {
      if (userId !== undefined) {
        const response = await axios.post(`https://mabkart.org/order/orderproduct/${bookId}/${userId}`);
        console.log(response.data, 'rehan');
        if (response) {
          setShowSuccessModal(true); // Show success modal on successful purchase
          // You can optionally reload the page here or perform any other action
        }
      } else {
        console.log("User id is undefined");
      }
    } catch (error) {
      console.error('Error purchasing book:', error);
    }
  };

  const openRazorpay = (orderId) => {
    const options = {
      key: 'rzp_live_X7mpyJo39bWsKb',
      amount: 1000,
      currency: 'INR',
      name: 'My Adventure Book',
      description: 'Merch Order',
      order_id: orderId,
      handler: function (response) {
        purchaseBook();
      },
      prefill: {
        name: 'John Doe',
        email: 'john@example.com',
        contact: '+1234567890',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate('/dashboard');
  };

  return (
    <div>
    

    <div>
      <Button variant="success" style={{ padding: '10px 20px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px'  }} onClick={createOrder}>
        Place Order
      </Button>
      {showSuccessModal && (
      <div style={{    
        position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,}}
     >

        <div style={{ background: 'white',
    padding: '20px',
    borderRadius: '10px',
    width: '50%',
    maxWidth: '80%',
    maxHeight: '80%',
    overflowY: 'auto',
    textAlign:'center',
    position: 'relative'}}>
          <p style={{ textAlign: 'center', fontSize: '22px' }}><span style={{textDecoration:'underline'}}>Payment Successful</span><br/> Your order is confirmed. <br/> Thank you 😊 !</p>
          <Button variant="primary" style={{width:'10%'}} onClick={handleCloseModal}>Close</Button>

        </div>
      </div>)}

      </div>
    </div>
  );
};

export default PaymentComponent;